import React from "react"
import Layout from "../components/Layout/Layout";
import {graphql, Link} from "gatsby";

const Blog = ({data}) => {
    const posts = data.allMdx.nodes
    if (posts.length === 0) {
        return (
            <Layout>
                <div className="container">
                <p>
                    Nejsou zde žádné člásnky :(
                </p>
                </div>
            </Layout>
        )
    }

    return (
        <Layout>
            <div className="container">
                <h1>Vítejte na blogu!</h1>

                {posts.map(post => {
                    const title = post.frontmatter.title || post.frontmatter.slug
                    return(
                        <Link to={post.frontmatter.slug}>
                            <span itemProp="headline">{title}</span><br />
                        </Link>
                    )
                })}

            </div>
        </Layout>
    );
}

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          slug
        }
      }
    }
  }
`

export default Blog;